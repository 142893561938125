import { FeedbackMailLink } from '@entities/company';
import { t } from '@lib/i18n';
import { PRICE_QUESTIONS } from '@assets/content/constants';
// constants
import { SUPPORT } from '@assets/content/text';
// styles
import { block } from 'bem-cn';
import clsx from 'clsx';
import { memo } from 'react';
import StaticIcon from '@ui/StaticIcon';
import AccordionMenu from '@ui/AccordionMenu';
import MediaQuery from '../../MediaQuery';

// ui
import Typography from '../../Typography';
import { Question } from './components';
import './style.scss';

const cn = block('price_support_block');

// -----------------------------------------------------------------

const BlockCard = ({ item, className }) => {
  return (
    <div className={className}>
      <Typography>
        {item.body}
      </Typography>
    </div>
  );
};

const SupportBlock = ({ items }) => {
  const accordionItems = items.map((x) => ({
    title: x.title,
    renderContent: () => <BlockCard className="[&>p>a]:text-primary400" item={x} />,
  }));
  return (
    <section className="lg:grid-layout rounded-2xl border-[3px] border-tertiary200 bg-white p-10 sm:mx-[-8px] sm:rounded-none sm:border-none sm:py-7 sm:px-3 md:mx-3 md:flex-col md:px-4.5 md:pt-[42px] md:pb-7 md-up:flex lg:mx-auto">
      <div className="lg-only:col-5 md:mb-[27px] md:flex md:items-center md:justify-between xl:w-[360px]">
        <div>
          <Typography color="blue2">{SUPPORT}</Typography>
          <Typography weight="semibold" variant="header2" color="deepBlue2" className="mt-1 sm:mb-5">
            {t('support_block.title')}
          </Typography>
        </div>
        <MediaQuery greaterThan="sm">
          <StaticIcon name="price-support" className="" />
        </MediaQuery>
      </div>
      <div className="lg-only:col-7 lg:ml-auto xl:w-[584px]">
        <AccordionMenu items={accordionItems} />
      </div>
    </section>
  );
};

export default memo(SupportBlock);
