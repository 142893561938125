// ui
// constants
import { TARIFFS, PRICE_QUESTIONS } from '@assets/content/constants';
// lang
import { RequestFeedback } from '@features/request-feedback';
// hooks
import { t } from '@lib/i18n';
import Container from '@ui/Container';
import MediaQuery from '@ui/MediaQuery';

import PriceBlock from '@ui/Price/PriceBlock';
import SupportBlock from '@ui/Price/SupportBlock';

import Typography from '@ui/Typography';
import { FeatureLayout } from '@widgets/layout';
// styles
import { block } from 'bem-cn';
import Slider from '@ui/Slider';
import clsx from 'clsx';
// context
import './style.scss';

const cn = block('price-page');
const tr = (key) => t(`tariffs.${key}`);

// ---------------------------------------------------------------------

const FeatureContent = () => (
  <>
    <Typography variant="header1" center>
      {tr('header')}
    </Typography>
    <Typography variant="subhead1" center className="!mt-4">
      {tr('subheader')}
    </Typography>
  </>
);

const Price = () => {
  return (
    <FeatureLayout 
      mainLayoutProps={{ seo: t('seo.price') }}
      featureChildren={<FeatureContent />}
      className="!pb-[190px] sm:!pb-[301px] md:!pb-[226px]"
    >
      <RequestFeedback.Price.Modal />
      <section className="xl:col-10 xl-down:grid-layout mx-auto mt-[-118px] mb-9 sm:mt-[-245px] md:mt-[-162px]">
        <MediaQuery at="sm">
          <Slider gutter={4}>
            {TARIFFS.map((tariff) => (
              <PriceBlock tariff={tariff} key={tariff.name} />
            ))}
          </Slider>
        </MediaQuery>
        <MediaQuery at="md">
          <Slider
            settings={{
              slidesToShow: 2.2,
              slidesToScroll: 1,
            }}
            gutter={4}
          >
            {TARIFFS.map((tariff) => (
              <PriceBlock tariff={tariff} key={tariff.name} />
            ))}
          </Slider>
        </MediaQuery>
        <MediaQuery greaterThan="md">
          <div
            className="flex gap-4"
          >
            {TARIFFS.map((tariff) => (
              <PriceBlock tariff={tariff} key={tariff.name} />
            ))}
          </div>
        </MediaQuery>
        <div className="mt-7 text-center">
          <Typography className={cn('caption')} variant="caption1">{t('tariffs.addition')}</Typography>
        </div>
      </section>
      <SupportBlock items={PRICE_QUESTIONS} />
      <div className="my-9 text-center [&>p>a]:text-primary400">
        <Typography className="" variant="body1">{t('support_block.text')}</Typography>
      </div>
    </FeatureLayout>
  );
};

export default Price;
