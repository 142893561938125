import { block } from 'bem-cn';
import './style.scss';

const cn = block('main-container');

const Container = ({
  children,
  className,
}) => (
  <div className={cn('wrap')
    .mix(className)}
  >
    {children}
  </div>
);

export default Container;
