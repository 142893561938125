// ui
import { t } from '@lib/i18n';
// styles
import { block } from 'bem-cn';
import { memo } from 'react';
import Typography from '../../../Typography';
import '../style.scss';

const cn = block('price_support_block');

// ---------------------------------------------------------------

const Question = ({ question }) => (
  <li>
    <Typography
      color="deepBlue2"
      className={cn('question', { title: true })}
      weight="medium"
    >
      {question.title}
    </Typography>
    <Typography color="deepBlue5" className={cn('question', { content: true })}>
      {question.content}
      {question.email
        && (
          <Typography color="blue2" variant="link1">
            <a className={cn('email')} href={`mailto:${question.email}?subject=${question.subject}`}>
              {question.email}
            </a>
            .
          </Typography>
        )}
    </Typography>

  </li>
);

export default memo(Question);
